import { TicketService, AgentService } from "@/services";
import Hashids from "hashids";
import Formatter from "@/helpers/formatter.helper";
// import { useState } from "vuex-composition-helpers";

const Types = {
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_LOADING: "SET_LOADING",
  FETCH_DATA_ID_SUCCESS: "FETCH_DATA_ID_SUCCESS",
  CREATE_TICKET: "CREATE_TICKET", // trigger in CREATE
  NEW_TAG: "NEW_TAG", // trigger when user type in chat information but the tag does not exist,
  FETCH_LOAD_DATA: "LOAD DATA",
  FETCH_DATA_COUNT: "FETCH_DATA_COUNT",
  FETCH_DATA_ROLE: "FETCH_DATA_ROLE",
  FETCH_DATA_USER: "FETCH_DATA_USER",
  FETCH_DATA_MESSAGE: "FETCH_DATA_MESSAGE",
  FECTH_ADD_DATA_MESSAGE: "FECTH_ADD_DATA_MESSAGE",
  FECTH_ADD_DATA_TICKET: "FECTH_ADD_DATA_TICKET",
  FETCH_DETAIL_TICKET: "FETCH_DETAIL_TICKET",
  FILTER_TICKET_AGENT: "FILTER_TICKET_AGENT",
  FILTER_SENT_TICKET: "FILTER_SENT_TICKET",
  FILTER_INBOX_TICKET: "FILTER_INBOX_TICKET",
  TOTAL_TICKET: "TOTAL_TICKET",
  FETCH_DATA_CUSTOMER: "FETCH_DATA_CUSTOMER",
  FETCH_DATA_STATUS: "FETCH_DATA_STATUS",
  FETCH_DATA_TYPE: "FETCH_DATA_TYPE",
  FETCH_DATA_PRIORITY: "FETCH_DATA_PRIORITY",
  FETCH_DATA_CHANNEL: "FETCH_DATA_CHANNEL",
  FETCH_DATA_SUBJECT: "FETCH_DATA_SUBJECT",
  FETCH_DATA_TRACK: "FETCH_DATA_TRACK",
  FETCH_DATA_TAGS: "FETCH_DATA_TAGS",
  FECTH_ADD_DATA_SUBJECT: "FECTH_ADD_DATA_SUBJECT",
  FECTH_ADD_DATA_TAGS: "FECTH_ADD_DATA_TAGS",
  FECTH_ADD_DATA_GROUP_MANAGEMENT: "FECTH_ADD_DATA_GROUP_MANAGEMENT",
  FETCH_AGENT_SUCESS: "FETCH_AGENT_SUCESS",
  SAVE_GROUP_AGENT: "SAVE_GROUP_AGENT",
  SAVE_MAX_PAGE: "SAVE_MAX_PAGE",
  SET_LOADING_GET_MESSAGE: "SET_LOADING_GET_MESSAGE",
  SET_UPDATE_TICKET: "SET_UPDATE_TICKET",
  LOADING_PAGE: "LOADING_PAGE",
  FETCH_DATA_COUNT_BY_AGENT: "FETCH_DATA_COUNT_BY_AGENT",
  FETCH_DATA_BRANCH_OFFICE: "FETCH_DATA_BRANCH_OFFICE",
  FETCH_TAG_DETAIL: "FETCH_TAG_DETAIL",
  FETCH_UPDATE_FILES: "FETCH_UPDATE_FILES",
  FETCH_USER_TICKETS: "FETCH_USER_TICKETS",
  FETCH_HISTORY_GROUP: "FETCH_HISTORY_GROUP",
  FETCH_HISTORY_AGENT: "FETCH_HISTORY_AGENT",
  FETCH_UPDATE_STATUS_TICKET: "FETCH_UPDATE_STATUS_TICKET",
  GET_USER_CHANGE_STATUS: "GET_USER_CHANGE_STATUS",
  FETCH_USER_SEEN: "FETCH_USER_SEEN",
  LOADING_PAGE_DETAIL_TICKET: "LOADING_PAGE_DETAIL_TICKET",
  CLEAR_DETAIL_TICKET: "CLEAR_DETAIL_TICKET",
  PRIVATE_NOTES: "PRIVATE_NOTES",
  PRIVATE_NOTES_COUNT: "PRIVATE_NOTES_COUNT",
  MERGE_TICKET_HISTORY: "MERGE_TICKET_HISTORY",
  TICKET_CHILD_INQUIRIES: "TICKET_CHILD_INQUIRIES",
  TICKET_CUSTOM_FIELD: "TICKET_CUSTOM_FIELD",
  CURRENT_PAGE: "CURRENT_PAGE",
  INIT_FILTER: "INIT_FILTER",
  BRANDS: "BRANDS",
  SET_SESSION: "SESSION",
  BODY_TEXT_INQUIRIES: "BODY_TEXT_INQUIRIES",
  GET_VISITING_AGENT: "GET_VISITING_AGENT",
  RESET_VISITING_AGENT: "RESET_VISITING_AGENT",
  SET_VISITING_AGENT: "SET_VISITING_AGENT",
  UPDATE_ATTRIBUTES: "UPDATE_ATTRIBUTES",
  SET_CATEGORY: "CATEGORY",
};

const state = {
  currentPage: 1,
  currentfilter: {
    status: null,
  },
  data: {},
  error: null,
  loading: false,
  loadingGetMessage: false,
  loadingPage: false,
  dataId: {},
  loadPage: {
    page: 1,
    loadMore: true,
    perPage: 7,
  },
  inbox: true,
  sent: false,
  count: [],
  role: [],
  user: [],
  message: {},
  filter_ticket: {},
  total_ticket: "",
  customers: [],
  status: [],
  branch_office: [],
  type: [],
  category: [],
  priority: [],
  channel: [],
  subject: [],
  track: [],
  tags: [],
  group_management: [],
  agents: [],
  save_group_agent: [],
  max_page: "",
  detailTicket: [],
  countbyagent: [],
  tagDetail: [],
  userTickets: [],
  historyGroup: "",
  historyAgent: "",
  userChangeStatus: "",
  loadingPageDetailTicket: false,
  revertStatus: false,
  private_notes: false,
  private_notes_count: 0,
  merge_ticket_history: [],
  ticket_child_inquiries: [],
  custom_field: [],
  brands: [],
  session: [],
  filterCondition: [
    {
      id: "assign_to_me",
      value: "assign_to_me",
      label: "Assign to me",
    },
    {
      id: "overdue",
      value: "overdue",
      label: "Overdue",
    },
  ],
  body_text_inquiries: [],
  visiting_agent: [],
  update_attributes: [],
};

const getters = {
  getTicket(state) {
    return state.data;
  },
  getDetailTicket(state) {
    return state.detailTicket;
  },
  getLoadingMessage(state) {
    return state.loadingGetMessage;
  },
  getLoadingPage(state) {
    return state.loadingPage;
  },
  getMaxPage(state) {
    return state.max_page;
  },
  getGroupManagement(state) {
    return state.group_management;
  },
  getMemberAgent(state) {
    return state.agents;
  },
  getSaveGroupAgent(state) {
    return state.save_group_agent;
  },
  getType(state) {
    return state.type;
  },
  getSubject(state) {
    return state.subject;
  },
  getPriority(state) {
    return state.priority;
  },
  getChannel(state) {
    return state.channel;
  },
  getTrack(state) {
    return state.track;
  },
  getTags(state) {
    return state.tags;
  },
  getStatus(state) {
    return state.status;
  },
  getCustomer(state) {
    return state.customers;
  },
  getTotal(state) {
    return state.total_ticket;
  },
  getSent(state) {
    return state.sent;
  },
  getInbox(state) {
    return state.inbox;
  },
  getCount(state) {
    return state.count;
  },
  getCountbyAgent(state) {
    return state.countbyagent;
  },
  getByTicketId(state) {
    return state.dataId;
  },
  loadData(state) {
    return state.loadPage;
  },
  getRole(state) {
    return state.role;
  },
  getUserData(state) {
    return state.user;
  },
  getMessage(state) {
    return state.message;
  },
  getFilterTicketAgent(state) {
    return state.data;
  },
  loading(state) {
    return state.loading;
  },
  setUpdateTicket(state) {
    return state.detailTicket;
  },
  getBranchOffice(state) {
    return state.branch_office;
  },
  getTagDetail(state) {
    return state.tagDetail;
  },
  error(state) {
    return state.error;
  },
  userTickets(state) {
    return state.userTickets;
  },
  historyGroup(state) {
    return state.historyGroup;
  },
  historyAgent(state) {
    return state.historyAgent;
  },
  userChangeStatus(state) {
    return state.userChangeStatus;
  },
  loadingPageDetailTicket(state) {
    return state.loadingPageDetailTicket;
  },
  checkOnRevertStatus(state) {
    return state.revertStatus;
  },
  getPrivateNotes(state) {
    return state.private_notes;
  },
  countPrivateNotes(state) {
    return state.private_notes_count;
  },
  mergeTicketHistory(state) {
    return state.merge_ticket_history;
  },
  getTicketChildInquiries(state) {
    return state.ticket_child_inquiries;
  },
  getCustomField(state) {
    return state.custom_field;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
  getInitFilter(state) {
    return state.currentfilter;
  },
  getBrands(state) {
    return state.brands;
  },
  updateSession(state) {
    return state.session;
  },
  getFilterCondition(state) {
    return state.filterCondition;
  },
  getBodyText(state) {
    return state.body_text_inquiries;
  },
  getVisitingAgent(state) {
    return state.visiting_agent;
  },
  updateAttributes(state) {
    return state.update_attributes;
  },
  setCategory(state) {
    return state.category;
  },
};

const mutations = {
  RESET_DETAIL_TICKET: (state) => {
    state.detailTicket = [];
  },
  [Types.FETCH_DATA_SUCCESS]: (state, payload) => (state.data = payload),
  [Types.FETCH_DATA_ID_SUCCESS]: (state, payload) => (state.dataId = payload),
  [Types.CHECK_ON_REVERT_STATUS]: (state, payload) =>
    (state.revertStatus = payload),
  [Types.CREATE_TICKET]: (state, payload) =>
    (state.data = [...state.data, payload]),
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.NEW_TAG]: (state, payload) => (state.data = [...state.data, payload]),
  [Types.FETCH_LOAD_DATA]: (state, payload) => (state.loadPage = payload),
  [Types.FETCH_DATA_COUNT]: (state, payload) => (state.count = payload),
  [Types.FETCH_DATA_ROLE]: (state, payload) => (state.role = payload),
  [Types.FETCH_DATA_USER]: (state, payload) => (state.user = payload),
  [Types.FETCH_DATA_MESSAGE]: (state, payload) => (state.message = payload),
  [Types.FECTH_ADD_DATA_MESSAGE]: (state, payload) =>
    state.message.push(payload),
  [Types.FECTH_ADD_DATA_TICKET]: (state, payload) =>
    state.data.data.unshift(payload),
  [Types.FILTER_SENT_TICKET]: (state, payload) => (state.sent = payload),
  [Types.FILTER_INBOX_TICKET]: (state, payload) => (state.inbox = payload),
  [Types.TOTAL_TICKET]: (state, payload) => (state.total_ticket = payload),
  [Types.FETCH_DATA_CUSTOMER]: (state, payload) => (state.customers = payload),
  [Types.FETCH_DATA_STATUS]: (state, payload) => (state.status = payload),
  [Types.FETCH_DATA_BRANCH_OFFICE]: (state, payload) =>
    (state.branch_office = payload),
  [Types.FETCH_DATA_TYPE]: (state, payload) => (state.type = payload),
  [Types.FETCH_DATA_SUBJECT]: (state, payload) => (state.subject = payload),
  [Types.FETCH_DATA_TRACK]: (state, payload) => (state.track = payload),
  [Types.FETCH_DATA_PRIORITY]: (state, payload) => (state.priority = payload),
  [Types.FETCH_DATA_CHANNEL]: (state, payload) => (state.channel = payload),
  [Types.FETCH_DATA_TAGS]: (state, payload) => (state.tags = payload),
  [Types.FECTH_ADD_DATA_SUBJECT]: (state, payload) =>
    state.subject.unshift(payload),
  [Types.FECTH_ADD_DATA_TAGS]: (state, payload) => state.tags.push(payload),
  [Types.FECTH_ADD_DATA_GROUP_MANAGEMENT]: (state, payload) =>
    (state.group_management = payload),
  [Types.FETCH_AGENT_SUCESS]: (state, payload) => (state.agents = payload),
  [Types.SAVE_GROUP_AGENT]: (state, payload) =>
    (state.save_group_agent = payload),
  [Types.SAVE_MAX_PAGE]: (state, payload) => (state.max_page = payload),
  [Types.FETCH_DETAIL_TICKET]: (state, payload) =>
    (state.detailTicket = payload),
  [Types.FETCH_UPDATE_FILES]: (state, payload) =>
    (state.detailTicket = payload),
  [Types.SET_LOADING_GET_MESSAGE]: (state, payload) =>
    (state.loadingGetMessage = payload),
  [Types.SET_UPDATE_TICKET]: (state, payload) => (state.detailTicket = payload),
  [Types.LOADING_PAGE]: (state, payload) => (state.loadingPage = payload),
  [Types.FETCH_DATA_COUNT_BY_AGENT]: (state, payload) =>
    (state.countbyagent = payload),
  [Types.FETCH_TAG_DETAIL]: (state, payload) => (state.tagDetail = payload),
  [Types.ERROR_SERVICE]: (state, payload) => (state.error = payload),
  [Types.FETCH_USER_TICKETS]: (state, payload) => (state.userTickets = payload),
  [Types.FETCH_HISTORY_GROUP]: (state, payload) =>
    (state.historyGroup = payload),
  [Types.FETCH_HISTORY_AGENT]: (state, payload) =>
    (state.historyAgent = payload),
  [Types.FETCH_UPDATE_STATUS_TICKET]: (state, payload) =>
    (state.detailTicket.status = payload),
  [Types.GET_USER_CHANGE_STATUS]: (state, payload) =>
    (state.userChangeStatus = payload),
  [Types.FETCH_USER_SEEN]: (state, payload) =>
    (state.detailTicket.seen = payload),
  [Types.LOADING_PAGE_DETAIL_TICKET]: (state, payload) =>
    (state.loadingPageDetailTicket = payload),
  [Types.CLEAR_DETAIL_TICKET]: (state) => (state.detailTicket = []),
  [Types.PRIVATE_NOTES]: (state) => (state.private_notes = []),
  [Types.PRIVATE_NOTES_COUNT]: (state, payload) =>
    (state.private_notes_count = payload),
  [Types.MERGE_TICKET_HISTORY]: (state, payload) =>
    (state.merge_ticket_history = payload),
  [Types.TICKET_CHILD_INQUIRIES]: (state, payload) =>
    (state.ticket_child_inquiries = payload),
  [Types.TICKET_CUSTOM_FIELD]: (state, payload) =>
    (state.custom_field = payload),
  [Types.CURRENT_PAGE]: (state, payload) => (state.currentPage = payload),
  [Types.INIT_FILTER]: (state, payload) => (state.currentfilter = payload),
  [Types.BRANDS]: (state, payload) => (state.brands = payload),
  [Types.BODY_TEXT_INQUIRIES]: (state, payload) =>
    (state.body_text_inquiries = payload),
  [Types.GET_VISITING_AGENT]: (state, payload) =>
    (state.visiting_agent = payload),
  [Types.RESET_VISITING_AGENT]: (state) => (state.visiting_agent = []),
  [Types.SET_VISITING_AGENT]: (state, payload) =>
    (state.visiting_agent = payload),
  [Types.UPDATE_ATTRIBUTES]: (state, payload) =>
    (state.update_attributes = payload),
  [Types.SET_CATEGORY]: (state, payload) => (state.category = payload),
};

const actions = {
  resetDetailTicket({ commit }) {
    commit("RESET_DETAIL_TICKET");
  },
  async setUpdateTicket({ commit }, payload) {
    commit(Types.SET_UPDATE_TICKET, payload);
  },
  async getMessage({ commit }, payload) {
    commit(Types.SET_LOADING_GET_MESSAGE, true);
    try {
      const { data: response } = await TicketService.getMessage(payload);
      commit(Types.FETCH_DATA_MESSAGE, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING_GET_MESSAGE, false);
  },
  async getMessageDiscussion({ commit }, payload) {
    commit(Types.SET_LOADING_GET_MESSAGE, true);
    try {
      const { data: response } = await TicketService.getMessageDiscussion(
        payload
      );
      commit(Types.FETCH_DATA_MESSAGE, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING_GET_MESSAGE, false);
  },
  async addSendMessage({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FECTH_ADD_DATA_MESSAGE, payload.message);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async updateStatusTicket({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FETCH_UPDATE_STATUS_TICKET, payload.status);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getUserChangeStatus({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.GET_USER_CHANGE_STATUS, payload.user);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async fetchUserSeen({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FETCH_USER_SEEN, payload.profile);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async deleteUserSeen({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await TicketService.deleteUserSeen(payload);
      commit(Types.CLEAR_DETAIL_TICKET);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async addCreatedTicket({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FECTH_ADD_DATA_TICKET, payload);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  getAllTicket({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.TOTAL_TICKET, payload["total"]);
      commit(Types.FETCH_DATA_SUCCESS, payload["data"]);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async reactiveFilesUpdate({ commit }, payload) {
    try {
      commit(Types.FETCH_UPDATE_FILES, payload);
      await TicketService.reactiveFilesUpdate(payload);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
  },
  async getDetailTicket({ commit, dispatch }, payload) {
    commit(Types.LOADING_PAGE_DETAIL_TICKET, true);
    try {
      const { data: response } = await TicketService.getDetailTicket(payload);
      // commit(Types.FETCH_DETAIL_TICKET, response.data[0]);
      if (typeof response.data.group === "undefined") {
        response.data = {
          ...response.data,
          group: {
            name: "Select Agent",
          },
        };
      }
      let arrayAgents = response.data.agent_ticket;
      response.data.agent_ticket = arrayAgents.map(function (item) {
        return {
          id: item.userPlatform["id"],
          label: item.userPlatform["name"],
        };
      });
      commit(Types.FETCH_DETAIL_TICKET, response.data);
      console.log('fetch ticket', response.data);
      // let GetStatusChange = response.data.status_change[0];
      const checkStatus = await TicketService.checkStatusOnRevertToOpen(
        payload.id
      );
      if (checkStatus.data.result == true) {
        commit(Types.FETCH_HISTORY_AGENT, "");
      } else {
        if (
          // eslint-disable-next-line no-constant-condition
          response.data.group_management == null ||
          response.data.group_management ||
          "--"
        ) {
          commit(Types.FETCH_HISTORY_AGENT, response.data.agent_ticket[0].id);
          dispatch("searchAgent", "");
        } else {
          commit(Types.FETCH_HISTORY_AGENT, response.data.agent_ticket[0].id);
          dispatch("getMemberAgent", response.data.group.id);
        }
      }
    } catch (err) {
      console.log(err);
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.LOADING_PAGE_DETAIL_TICKET, false);
  },
  getSentTicketAgent({ commit, rootGetters }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      // eslint-disable-next-line no-inner-declarations
      function filterDataSent(item) {
        return item["user_id"] == rootGetters.localStorage["decodedUserId"];
      }
      let ticket = payload["data"].filter(filterDataSent);
      commit(Types.TOTAL_TICKET, payload["total"]);
      commit(Types.FETCH_DATA_SUCCESS, ticket);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  setHistoryAgent({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FETCH_HISTORY_AGENT, payload);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  getInboxTicketAgent({ commit, rootGetters }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      // eslint-disable-next-line no-inner-declarations
      function filterDataInbox(item) {
        return (
          item.user_info["email"] == rootGetters.localStorage.user["email"]
        );
      }
      let ticket = payload["data"].filter(filterDataInbox);

      commit(Types.TOTAL_TICKET, payload["total"]);
      commit(Types.FETCH_DATA_SUCCESS, ticket);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTicketAll({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getTicketAll(payload);
      commit(Types.FETCH_DATA_SUCCESS, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // async getTicket({ commit }, payload) {
  //   commit(Types.SET_LOADING, true);
  //   try {
  //     // let isAgent = rootGetters["accessibility/isAgent"];
  //     // if (isAgent) {
  //     //   if (payload["all"]) {
  //     //     const { data: response } = await TicketService.getTicket(payload);
  //     //     dispatch("getAllTicket", response);
  //     //   }
  //     // }

  //     // else if (!isAgent) {
  //     //   dispatch('getAllTicket', response)
  //     // }
  //     const { data: response } = await TicketService.getAll(payload);
  //     // function filterTicket(item) {
  //     //   return (
  //     //     item.agent_ticket.userPlatform["email"] ==
  //     //     rootGetters.localStorage.user["email"]
  //     //   );
  //     // }
  //     // let filters = response["data"].filter(filterTicket);

  //     // let max_filter = filters.length / 10;
  //     // if (max_filter > 1) {
  //     //   commit(Types.SAVE_MAX_PAGE, Math.ceil(max_filter));
  //     // } else {
  //     //   commit(Types.SAVE_MAX_PAGE, 1);
  //     // }
  //     commit(Types.SAVE_MAX_PAGE, response.last_page);
  //     commit(Types.FETCH_DATA_SUCCESS, response.data);
  //   } catch (err) {
  //     commit(Types.ERROR_SERVICE, err);
  //   }
  //   commit(Types.SET_LOADING, false);
  // },
  // async newMessage(context, payload) {
  //   context.commit("HANDLE_NEW_MESSAGE", payload);
  //   if (
  //     !_.isNull(context.getters.selectedRoom) &&
  //     context.getters.selectedRoom.id == payload.room.id
  //   ) {
  //     context.dispatch("updateUnreadMessage", {
  //       room: payload.room,
  //       senderId: context.rootGetters.localStorage.decodedUserId,
  //       forceUpdate: true
  //     });
  //     context.commit("SET_UNREAD_COUNT", {
  //       ...payload,
  //       event: "new_message_selected"
  //     });
  //   } else {
  //     context.commit("SET_UNREAD_COUNT", { ...payload, event: "new_message" });
  //   }
  // },
  async getCount({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getCount(data);
      commit(Types.FETCH_DATA_COUNT, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getCountbyAgent({ commit }, data) {
    commit(Types.LOADING_PAGE, true);
    try {
      const { data: response } = await TicketService.getCountbyAgent(data);
      commit(Types.FETCH_DATA_COUNT_BY_AGENT, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.LOADING_PAGE, false);
  },
  async getRole({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getRole();
      commit(Types.FETCH_DATA_ROLE, response);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getUserData({ commit }) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getUserData();
      commit(Types.FETCH_DATA_USER, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async updateFilterTicket({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getAll(payload);
      commit(Types.SAVE_MAX_PAGE, response.last_page);
      commit(Types.FETCH_DATA_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async filter({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.filter(data);
      commit(Types.TOTAL_TICKET, response["total"]);
      commit(Types.FETCH_DATA_SUCCESS, response["data"]);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getInformation({ commit }, paylod) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getInformation(paylod);
      commit(Types.FETCH_DATA_ID_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getCustomer({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getCustomer(value);
      let filters = response.data.map(function (item) {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
          email: item.email,
          data: item,
        };
      });
      commit(Types.FETCH_DATA_CUSTOMER, filters);
      return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getType({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getType(payload);
      // let filters = response.map(function (item) {
      //   return {
      //     value: item.slug,
      //     label: item.type,
      //     options: item.children,
      //   };
      // });
      let filters = response;

      commit(Types.FETCH_DATA_TYPE, filters);
      return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async createTicketCategory({ commit }, payload) {
    const { data: response } = await TicketService.createTicketCategory(
      payload
    );

    commit("SET_CATEGORY", response.data);
    return response.data;
  },

  async updateTicketCategory({ commit }, payload) {
    const { data: response } = await TicketService.updateTicketCategory(
      payload
    );

    commit("SET_CATEGORY", response.data);
    return response.data;
  },

  async deleteTicketCategory({ commit }, payload) {
    const { data: response } = await TicketService.deleteTicketCategory(
      payload
    );

    commit("SET_CATEGORY", response.data);
    return response.data;
  },

  async getTrack({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getTrack(value);
      commit(Types.FETCH_DATA_TRACK, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async uploadFoto({ commit }, file) {
    commit(Types.SET_LOADING, true);
    try {
      await TicketService.uploadFoto(file);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // Tags
  async addTags({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FECTH_ADD_DATA_TAGS, value);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTags({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getTags(value);
      let filters = response.data.map(function (item) {
        return {
          id: item.name,
          value: item.name,
          label: item.name,
        };
      });
      commit(Types.FETCH_DATA_TAGS, filters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getTagDetail({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getTagDetail(value);
      response.data.map(function (item) {
        return {
          id: item.name,
          label: item.name,
        };
      });
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // Tags
  async getPriority({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getPriority(payload);
      let filters = response.data.map(function (item) {
        return {
          id: item.slug,
          priority: item.priority,
          value: item.slug,
          label: item.priority,
          color: item.color,
        };
      });
      commit(Types.FETCH_DATA_PRIORITY, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  //Condition: Merged or Overdue
  async getCondition({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getCondition(payload);
      let filters = response.data.map(function (item) {
        return {
          value: item.slug,
          label: item.condition,
          color: item.color,
        };
      });
      commit(Types.FETCH_DATA_CONDITION, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  //end of Condition
  //Channel
  async getChannel({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getChannel(payload);
      let filters = response.map(function (item) {
        return {
          id: item.id,
          label: Formatter.upperCaseFromSlug(item.name),
        };
      });
      commit(Types.FETCH_DATA_CHANNEL, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  //end of Channel
  // Group
  async getGroup({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getGroup(payload);
      let filters = response.data.map(function (item) {
        return {
          value: item.slug,
          label: item.group,
        };
      });
      commit(Types.FETCH_DATA_GROUP, filters);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // end of Group
  // Subject
  async addSubject({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      commit(Types.FECTH_ADD_DATA_SUBJECT, value);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getSubject({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getSubject(value);
      let filters = response.data.map(function (item) {
        return {
          value: item.subject,
          label: item.subject,
        };
      });
      commit(Types.FETCH_DATA_SUBJECT, filters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // End Subject
  // Status
  async getStatus({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getStatus(payload);
      let filters = response.data.map(function (item) {
        return {
          id: item.slug,
          status: item.status,
          value: item.slug,
          label: item.status,
          color: item.color,
        };
      });

      filters = filters.filter((status) => {
        return status.value !== "closed";
      });
      commit(Types.FETCH_DATA_STATUS, filters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // End Branch Office
  async getBranchOffice({ commit }, payload = "") {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getBranchOffice(payload);
      let filters = response.data.map(function (item) {
        return {
          id: item.id,
          value: item.slug,
          label: item.branch_office,
        };
      });
      commit(Types.FETCH_DATA_BRANCH_OFFICE, filters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // End getBranchOffice
  // search agent
  async searchAgent({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await AgentService.getSearchTeamMember(value);
      let filters = response.data.map(function (item) {
        return {
          id: item.user.id,
          value: item.user.id,
          // label: item.user.name + "  -  " + item.user.email,
          label: item.user.name,
          email: item.user.email,
          data: item.user,
        };
      });
      commit(Types.FETCH_AGENT_SUCESS, filters);
    } catch (error) {
      commit(Types.ERROR_SERVICE, error);
    }
    commit(Types.SET_LOADING, false);
  },
  // end search agent
  // get member Agent
  async getMemberAgent({ commit, state }, value) {
    commit(Types.SET_LOADING, true);
    let save_group_agent = state.save_group_agent;
    if (save_group_agent.length > 0) {
      let response = save_group_agent.filter(function (item) {
        return item.id === value;
      });

      if (response.length > 0) {
        try {
          let filters = response[0].agent_group.map(function (item) {
            return {
              id: item.user_platform.id,
              value: item.user_platform.id,
              label:
                item.user_platform.name + "  -  " + item.user_platform.email,
              data: item.user_platform,
            };
          });
          let selectAllGroup = {
            id: filters,
            value: filters,
            label: "--",
          };
          let allFilters = [selectAllGroup, ...filters];
          commit(Types.FETCH_AGENT_SUCESS, allFilters);
        } catch (error) {
          commit(Types.ERROR_SERVICE, error);
        }
      }
    }
    commit(Types.SET_LOADING, false);
  },
  // End get member Agent
  // Group Management
  async getGroupManagement({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getGroupManagement(value);

      let filters = response.data.map(function (item) {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        };
      });
      let selectAgent = {
        id: "--",
        value: "--",
        label: "--",
      };
      let allFilters = [selectAgent, ...filters];
      commit(Types.SAVE_GROUP_AGENT, response.data);
      commit(Types.FECTH_ADD_DATA_GROUP_MANAGEMENT, allFilters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getGroupManagementNoPagination({ commit }, value) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } =
        await TicketService.getGroupManagementNoPagination(value);
      let filters = response.data.map(function (item) {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        };
      });
      let selectAgent = {
        id: "--",
        value: "--",
        label: "--",
      };
      let allFilters = [selectAgent, ...filters];
      commit(Types.SAVE_GROUP_AGENT, response.data);
      commit(Types.FECTH_ADD_DATA_GROUP_MANAGEMENT, allFilters);
      // return filters;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  // End Group Management
  async getByTicketId({ commit }, paylod) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.getByTicketId(paylod);
      commit(Types.FETCH_DATA_ID_SUCCESS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  checkStatusOnRevertToOpen({ commit }, payload) {
    try {
      const { data: response } =
        TicketService.checkStatusOnRevertToOpen(payload);

      commit(Types.CHECK_ON_REVERT_STATUS, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
  },
  async deleteData({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: responseDeletes } = await TicketService.destroy(data);
      return responseDeletes;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async createTicket({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await TicketService.create(data);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getByUserId({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      let hashids = new Hashids("d161t4lSTEREO", 6);
      const hashedUserId = hashids.encode(payload);
      let { data: response } = await TicketService.getByUserId(hashedUserId);

      if (response.data.length < 1) {
        let { data: ticket } = await TicketService.checkOnShowTicket(
          hashedUserId
        );
        response = ticket;
      }

      commit(Types.FETCH_USER_TICKETS, response.data);

      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getByRoomId({ commit }, payload) {
    // commit(Types.SET_LOADING, true);
    try {
      let { data: response } = await TicketService.getByRoomId(payload);

      commit(Types.FETCH_USER_TICKETS, response.data);

      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    // commit(Types.SET_LOADING, false);
  },
  async editTicket({ commit }, data) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await TicketService.updateTicket(data, data.id);
      return response;
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async updateSession({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const { data: response } = await TicketService.updateSession(payload);
      commit(Types.SET_SESSION, response.data);
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  async getPrivateNotes({ commit }, data) {
    try {
      const { data: response } = await TicketService.getPrivateNotes(data);
      commit(Types.PRIVATE_NOTES, response.data);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async countPrivateNotes({ commit }, data) {
    try {
      const { data: response } = await TicketService.countPrivateNotes(data);
      commit(Types.PRIVATE_NOTES_COUNT, response.data);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async mergeTicketHistory({ commit }, data) {
    try {
      const { data: response } = await TicketService.mergeTicketHistory(data);
      commit(Types.MERGE_TICKET_HISTORY, response.data);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async getTicketChildInquiries({ commit }, data) {
    try {
      const { data: response } = await TicketService.getTicketChildInquiries(
        data
      );
      commit(Types.TICKET_CHILD_INQUIRIES, response.data);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async getCustomField({ commit }, data) {
    try {
      const { data: response } = await TicketService.getCustomField(data);
      commit(Types.TICKET_CUSTOM_FIELD, response.data);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async getBrands({ commit }, data) {
    try {
      let res = [];
      if (data) {
        const { data: response } = await TicketService.getBrands(data);
        res = response.data.map(function (item) {
          var email = item.integration_data ? item.integration_data.email : "";
          var domain = email.split("@");
          const name =
            item.integration_data.name ?? item.integration_data.username;

          return {
            id: item.id,
            value: item.id,
            label: item.integration_data ? `${domain[1]} - ${name}` : "",
          };
        });
      }
      commit(Types.BRANDS, res);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  async getAllGroupManagement({ commit }, data) {
    try {
      const { data: response } =
        await TicketService.getGroupManagementNoPagination(data);
      let filters = response.data.map(function (item) {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        };
      });

      commit(Types.FECTH_ADD_DATA_GROUP_MANAGEMENT, filters);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },

  async searchCustomer({ commit }, data) {
    try {
      const { data: response } = await TicketService.searchCustomer(data);
      if (response.length > 0) {
        let filters = response.map(function (item) {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
            email: item.email,
            data: item,
          };
        });

        commit(Types.FETCH_DATA_CUSTOMER, filters);
        return filters;
      } else {
        commit(Types.FETCH_DATA_CUSTOMER, []);
      }
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
  setBodyText({ commit }, data) {
    commit(Types.BODY_TEXT_INQUIRIES, data);
  },
  async setVisitingAgent({ commit }, data) {
    try {
      const { data: response } = await TicketService.setVisitingAgent(data);
      console.log("SET DATA ", response.data.seen);
      commit(Types.SET_VISITING_AGENT, response.data.seen);
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },

  getVisitingAgent({ commit }, data) {
    commit(Types.GET_VISITING_AGENT, data);
  },

  updateVisitingAgent({ commit }, data) {
    console.log("update Data ", data);
    commit(Types.SET_VISITING_AGENT, data);
  },

  resetVisitingAgent({ commit }) {
    commit(Types.RESET_VISITING_AGENT);
  },

  async updateAttributes({ commit }, data) {
    try {
      const { data: response } = await TicketService.updateAttributes(data);

      commit(Types.UPDATE_ATTRIBUTES);
      return response.data;
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },

  async updateShowField({ commit }, data) {
    try {
      const { data: response } = await TicketService.updateShowField(data);
      return response.data;
    } catch (e) {
      commit(Types.ERROR_SERVICE, e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
