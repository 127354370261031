const prefixName = "call-center.call-center";

const callCenterRoute = [
  {
    path: "/:appId/call-center",
    name: `${prefixName}`,
    redirect: {
      name: `${prefixName}.dashboard`,
    },
  },
  {
    path: "/:appId/call-center/dashboard",
    name: `${prefixName}.dashboard`,
    meta: { title: "Call Center Dashboard" },
    component: () => import("@/views/call-center/pages/Dashboard.vue"),
  },
  {
    path: "/:appId/call-center/recording",
    name: `${prefixName}.recording`,
    meta: { title: "Call Center Recording" },
    component: () => import("@/views/call-center/pages/Recording.vue"),
  },
  {
    path: "/:appId/call-center/report",
    name: `${prefixName}.report`,
    meta: { title: "Call Center Report" },
    component: () => import("@/views/call-center/pages/Report.vue"),
  },
];

export default callCenterRoute;
